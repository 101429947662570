<template>
  <BasicModal
    :action="action"
    action-title="Create Patch"
  >
    <div class="header">
      <ErrorIcon />
      <h4>Your Patches are paused</h4>
    </div>
    <p>
      You’ve used 100% of your monthly Actions. You can create new Patches, but
      <strong>you won't be able to turn any Patches on until {{ usageCycleEndsAt }}.</strong>
    </p>
    <p>
      Proceed anyway?
    </p>
    <p>
      You can also <router-link to="/account/plans" @click="close()">upgrade</router-link>,
      which will unlock all your Patches immediately.
    </p>
  </BasicModal>
</template>

<script>

  import { mapGetters } from 'vuex'

  import BasicModal from '@/components/modals/_BasicModal.vue'

  import ErrorIcon from '@/assets/icons/error.svg'

  export default {
    components: {
      ErrorIcon,
      BasicModal,
    },
    props: {
      action: {
        type: Function,
        required: true,
      },
    },
    computed: {
      ...mapGetters('user', ['usageCycleEndsAt']),
    },
    methods: {
      close() {
        this.action()
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .header
    @apply mb-4

    @apply flex
    @apply gap-3
    @apply items-center

    h4
      @apply mb-0

    svg
      @apply w-8
      @apply h-8
      @apply text-danger-700

  p + p
    @apply mt-4

</style>
